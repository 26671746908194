import React from "react";
import LinearReveal from "../../components/animation/LinearReveal";
import Layout from "../../components/Layout";
import Meta from "../../components/Meta";
import * as styles from "../../styles/product.module.css";
import * as heroStyles from "../../styles/hero-section.module.css";
import HeroSection from "../../components/HeroSection";

export default function Timesheet() {
  const application = [
    {
      imageSrc: "/img/products/timesheet/img-1.jpg",
      title: "Timesheet for the week is automatically created for each employee",
    },
    {
      imageSrc: "/img/products/timesheet/img-2.jpg",
      title:
        "Timesheet Line Items for the week with Attendance and Absence as the types is entered",
    },
    {
      imageSrc: "/img/products/timesheet/img-3.jpg",
      title: "Desktop and Mobile enabled",
    },
    {
      imageSrc: "/img/products/timesheet/img-4.jpg",
      title: "Create Employees, Projects, Charge Codes and Project Charge Codes",
    },
    {
      imageSrc: "/img/products/timesheet/img-5.jpg",
      title: "Glean insights on Project, Activities and Absence",
    },
  ];
  return (
    <Layout>
      <Meta
        title="Timesheet - DBT"
        description="Native Time Tracking solution supporting Project Time tracking, Approvals,
            Vacation tracking and capture on the fly via Mobile."
      />
      <HeroSection
        title="Time Tracking for Project Management"
        titleClass={"text-primary " + heroStyles.heroTitle}
        imageClass={heroStyles.timesheetImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <article className={styles.article}>
        <LinearReveal>
          <p className={styles.description + " pt-5"}>
            Native Time Tracking solution supporting Project Time tracking, Approvals,
            Vacation tracking and capture on the fly via Mobile.{" "}
          </p>
          <p className={styles.description}>
            <span className="text-heading has-text-weight-semibold">
              {" "}
              Features include -{" "}
            </span>{" "}
            Timesheet entry using Salesforce app in mobile, sharing data security, and
            Reports / Dashboards to manage employee attendance and absence.
          </p>
          <p className={styles.description}>
            Create Timesheet Items - via lightning Salesforce app and via mobile. Capture
            and modify time items via Mobile apps.
          </p>
          <p className={styles.description}>
            Report Timesheet Items using criteria like Project, Activity and Absences.
            Track your status via dashboards, reports, and alerts.
          </p>
          <p className={styles.description}>Vacation and time off tracking.</p>
        </LinearReveal>

        {application.map((item, index) => (
          <LinearReveal key={index}>
            <p className={styles.description2}>{item.title}</p>
            <div className={"is-flex is-justify-content-center pt-5 "}>
              <img src={item.imageSrc} alt="DBT Timesheet" />
            </div>
          </LinearReveal>
        ))}
      </article>
    </Layout>
  );
}
